import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';


const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({providedIn: 'root'})
export class ExcelService {
    constructor() { }
    public exportAsExcelFile(json: any[], excelFileName: string): void {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        //worksheet['!cols'] = [{ wch: 60 }];
        //worksheet['!rows'] = [{ hpx: 24, }];
        const workbook: XLSX.WorkBook = { Sheets: { 'Datos': worksheet }, SheetNames: ['Datos']};
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
        this.saveAsExcelFile(excelBuffer, excelFileName);
    }

    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_' + new Date().getTime() + EXCEL_EXTENSION);
    }

    public exportAsExcelHtml(element:any, name:string, titulo:string){
        const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
        //ws['A1'].v = "Hola mundo" 
       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       
       XLSX.utils.book_append_sheet(wb, ws, 'Datos');

       /* save to file */
       XLSX.writeFile(wb, name + '_' + new Date().getTime() + EXCEL_EXTENSION);
    }
}