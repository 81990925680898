import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup,FormBuilder,Validators,FormArray, ValidatorFn  } from '@angular/forms';
import {ApiValidacionService} from './api-validacion.service'
import { isNull, isUndefined } from 'util';

@Injectable({providedIn: 'root'})
export class ValidadorService {
    constructor() { }
    /**
     *
     * retorna el texto segun el Error que presente
     * @param {AbstractControl} control
     * @returns Mensaje de error
     * @memberof ValidadorService
     */
    public getMensajeError(control:AbstractControl){
        if(control.hasError('required')) return "El campo es requerido"
        if(control.hasError('minlength')) return "El campo no cumple con los caracteres minimos ("+ control.errors.minlength.requiredLength +")"
        if(control.hasError('maxlength')) return "El campo no cumple con los caracteres maximos ("+ control.errors.maxlength.requiredLength +")"
        if(control.hasError('isIdentificacionAvailable')) return "La identificación no es correcta "
        if(control.hasError('email')) return "Correo no valido"
        if(control.hasError('pattern')) return "La información ingresada es invalida"
        if(control.hasError('NoPassswordMatch')) return "Confirmación erronia "
        if(control.hasError('isEmailAvailable')) return "El correo electronico es incorrecto"
        if(control.hasError('isCampoUnicoUsuario'))  return "Se encuentra registrado en otro usuario"
    }

    public getNivelPasswors(control:AbstractControl){
      const num = /\d/.test(control.value)
      const minisculas = /[a-z]/.test(control.value)
      const mayusculas = /[A-Z]/.test(control.value)
      const especiales  = /[\^$.*+?=!:|\\/()\[\]{}]/.test(control.value)
      /*console.log("Validacion tipos-->",num,minisculas,mayusculas,especiales, control.value.length)
      console.log("Validacion num caracter-->",control.value.length >=10, '&&' ,control.value.length <= 11 )
      console.log("Validacion num caracter-->",(control.value.length >11 && control.value.length <= 16 ))*/
      /** Nivel bajo */
      if(num  && (minisculas || mayusculas) && control.value.length < 10) return 1
      /** Nivel medio */
      if(num && control.value.length >=10
          && (minisculas && mayusculas) && !especiales) return 2 
      /** Nivel alto */
      if(num && control.value.length > 11 
          && (minisculas && mayusculas) && especiales) return 3  
      /** Nivel no ingreso nada */
      return 1
    }

    public passwordMatchValidator(control: AbstractControl) {
      const password: string = control.get('password').value; 
      const confirmPassword: string = control.get('confirm').value; 
      if (password !== confirmPassword) {
        control.get('confirm').setErrors({ NoPassswordMatch: true });
        return false
      }
      return true
    }

    public desactivarMenu(menu:any){
      if(menu.children.length > 0){
        this.desactivarMenu(menu.children)
      }else{
        for(let item of menu)
          menu.activo = false
        return null
      }
    }

    public getMenu(lista:any){
      let menu: any =[]
      this.crearItemsMenu(lista, null, menu, 1)
      return menu;
    }
  
    private crearItemsMenu(lista: any, nodoPadre:any, menu:any, nivel:number){
      let hijos = lista.filter((x:any)=> x.menu == nodoPadre)
      if(!isNull(hijos)){
        let count = 0
        for(let hijo of hijos){
          hijo["children"]=[]
          hijo['activo']= false
          hijo['level'] = nivel
          if(hijo.path != null){
            hijo["path"] = hijo.path.replace("?/?",'0/0')
          }
            
          menu.push(hijo)
          this.crearItemsMenu(lista,hijo.id,menu[count].children, nivel+1)
          count ++
        }
      }else{
        menu["children"]=[]
        return null
      }
    }
  
    /**
     *
     * Valida Indetifcicacion por tipo
     * @static
     * @param {ApiValidacionService} apiValidacionService
     * @param {string} tipoIdentificacion
     * @returns AbstractControl con su respectivo error si lo tendria
     * @memberof ValidadorService
     */
    static validarIdentificacion(apiValidacionService: ApiValidacionService, tipoIdentificacion:string){
      return (control: AbstractControl) => {
        console.log("******** Dato-->", control, tipoIdentificacion)
        if(control.value == '' || control.value == null) return {isIdentificacionAvailable: true}
        
        return apiValidacionService.getValidarIdentificacion(tipoIdentificacion,control.value).subscribe(
          (response:any)=>{
            return control.setErrors((response.result)? null : {isIdentificacionAvailable: true}) 
          }
        )
      };
    }
    static validarIdentificacionGroup(apiValidacionService: ApiValidacionService){
      return (grupo: FormGroup) => {
        if(grupo.get('identificacion').value == '' || grupo.get('identificacion').value == null) 
          return  grupo.get('identificacion').setErrors({isCampoUnicoUsuario: true})
          
        return apiValidacionService.getValidarIdentificacion(
          grupo.get('tipoIdentificacion').value,
          grupo.get('identificacion').value
        ).subscribe(
          (response:any)=>{
            return (response.result) ? grupo.get('identificacion').setErrors(null) : grupo.get('identificacion').setErrors({isIdentificacionAvailable: true})
          }
        )
      };
    }

    static validarUnicosUsuarioGroup(
      apiValidacionService: ApiValidacionService, 
      campo:string,
      tipo:number
      ){
      return (grupo: FormGroup) => {
        if(grupo.get(campo).value == '' || grupo.get(campo).value == null) 
          return  grupo.get(campo).setErrors({isCampoUnicoUsuario: true})

        return apiValidacionService.getVailidaUnicosUsuario(
          grupo.get('id').value,
          grupo.get(campo).value,
          tipo
        ).subscribe(
          (response:any)=>{
            return (response.result) ? grupo.get(campo).setErrors(null) : grupo.get(campo).setErrors({isCampoUnicoUsuario: true})
          }
        )
      };
    }
    /**
     *
     *Valida email 
     * @static
     * @param {ApiValidacionService} apiValidacionService
     * @returns bstractControl con su respectivo error si lo tendria
     * @memberof ValidadorService
     */
    static validarEmial(apiValidacionService: ApiValidacionService){
      return (control: AbstractControl) => {
        if(control.value == '' || control.value == null) return {isEmailAvailable: true}
        return apiValidacionService.getValidarEmail(control.value).subscribe(
          (response:any)=>{
            return control.setErrors((response.result)? null : {isEmailAvailable: true}) 
          }
        )
      };
    }

    /** Reporte */
  public getHeader(lista:any){
      let idRubros = Array.from(new Set(lista.map((item: any) => item.idRubro)))
      let headers:any=[]
      for(let idRubro of idRubros){
          let item = lista.find((x:any) => x.idRubro == idRubro)
          if(!isUndefined(item)){
              headers.push({id:item.idRubro,descripcion:item.rubroDescripcion,alias:item.idRubro,search:'',activo:false,viewSearch:false})
          }
          
      }
      return headers
  }
  public getEmpleado(lista:any){
      let idEmpleados = Array.from(new Set(lista.map((item: any) => item.idEmpleado)))
      let headers = this.getHeader(lista)
      let nomina:any =[]
      for(let idEmpelado of idEmpleados){
          let empleado = lista.filter((x:any)=> x.idEmpleado == idEmpelado) 
          if(idEmpleados.length > 0){
              let item = {
                  id:idEmpelado,
                  identificacion:empleado[0].identificacion,
                  descripcion: empleado[0].empleado,
              }
              if(headers.length > 0){
                  for(let rubro of headers){
                      let dato = empleado.find((x:any)=> x.idRubro == rubro.id)
                      if(!isUndefined(dato)) item[rubro.id]=  dato.valor
                  }
              }
              nomina.push(item)
          }
      }
      return nomina
  }

  public verficarFirma(fechaFirma:any){
    if(fechaFirma == null || fechaFirma == undefined ) return {valida:0, fecha:null}
    let fechaActual = new Date()
    let fechaCertificado = fechaActual.setTime(Date.parse(fechaFirma.slice(0,10))) 
    let valides = fechaActual.getTime() > fechaCertificado ? false : true
    return {valida:valides ? 1: 2, fecha:fechaFirma.slice(0,10)}
  }

}