import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AdministradorComponent} from './layout/administrador/administrador.component'
import {PageWebComponent} from './layout/page-web/page-web.component'
import {AuthenticationComponent} from './layout/authentication/authentication.component'
import {AuthorizatedGuard} from './core/shared/guard/authorizated-guard.guard'
import {PermisosGuard} from './core/shared/guard/permisos.guard'

const routes: Routes = [
  {
    path:'',
    component:AuthenticationComponent,
    children:[
      { path: '', pathMatch: 'full', redirectTo: '/authentication/login' },
      { 
        path: 'authentication', 
        loadChildren: () => import('./core/usuario/authentication/authentication.module').then(m => m.AuthenticationModule)
      }
    ]
  },
  
  {
    path: '',
    component: AdministradorComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: '/dashboard' },
      { 
        path: 'welcome', 
        loadChildren: () => import('./pages/welcome/welcome.module').then(m => m.WelcomeModule) 
      },
      { 
        path: 'nomina', 
        loadChildren: () => import('./pages/nomina/nomina.module').then(m => m.NominaModule),
        canActivate: [AuthorizatedGuard] 
      },
      { 
        path: 'usuario', 
        loadChildren: () => import('./core/usuario/administrador/administrador-usuario.module').then(m => m.AdministradorUsuarioModule),
        canActivate: [AuthorizatedGuard]  
      },
      { 
        path: 'dashboard', 
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthorizatedGuard]  
      },
      { 
        path: 'periodo', 
        loadChildren: () => import('./core/administrativo/periodo/periodo.module').then(m => m.PeriodoModule),
        canActivate: [AuthorizatedGuard]  
      },
      { 
        path: 'empresa', 
        loadChildren: () => import('./core/administrativo/empresa/empresa.module').then(m => m.EmpresaModule),
        canActivate: [AuthorizatedGuard]  
      },
      { 
        path: 'asistencia', 
        loadChildren: () => import('./pages/asistencia/asistencia.module').then(m => m.AsistenciaModule),
        canActivate: [AuthorizatedGuard]  
      },
      { 
        path: 'salud', 
        loadChildren: () => import('./pages/salud-ocupacional/salud-ocupacional.module').then(m => m.SaludOcupacionalModule),
        canActivate: [AuthorizatedGuard]  
      },
      { 
        path: 'clienteProveedor', 
        loadChildren: () => import('./pages/cliente-proveedor/cliente-proveedor.module').then(m => m.ClienteProveedorModule),
        canActivate: [AuthorizatedGuard]  
      },
      { 
        path: 'inventario', 
        loadChildren: () => import('./pages/inventario/inventario.module').then(m => m.InventarioModule),
        canActivate: [AuthorizatedGuard]  
      }
    ]
  },
  
  
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
