import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import {LoopBackAuthService} from '../service/loop-back-auth.service'

@Injectable({
  providedIn: 'root'
})

export class AuthorizatedGuard implements CanActivate {
  constructor(
    private router:Router,
    private loopBackAuthService:LoopBackAuthService
  ){}
  
  canActivate(){
    if(localStorage.getItem('token') != null && localStorage.getItem('LoopBackAuth') != null) {
      if(this.loopBackAuthService.load().usuario.cambiarContrasenia){
        this.router.navigate(['/authentication/new-password'])
        return true
      }
      return true
    }
    this.router.navigate(['/'])
    return false;
  }
}
