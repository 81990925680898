import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {Observable} from "rxjs";
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import {LoopBackAuthService} from './loop-back-auth.service'

@Injectable()
export class ApiCoreService{
    constructor( 
        private http: HttpClient,
        private loopBackAuthService:LoopBackAuthService
        ){}
    getTipoIdentificacion(idTransaccion:number){
        return this.http.get<any>(environment.apiUrl+'api/core/tipoIdentificacion/'+idTransaccion)
    }

    /** Permisos */
    getMenu(){
        let idUsuario = this.loopBackAuthService.load().usuario.inside
        let idPuntoEmision = 1
        return this.http.get<any>(environment.apiUrl+'api/permiso/menu/'+idUsuario+"/"+idPuntoEmision)
    }
    /** CRUD Periodos */
    getPeriodosEmpresa(idEmpresa:number){
        return this.http.get<any>(environment.apiUrl+'api/core/periodos/'+idEmpresa)
    }
    getPeriodos(){
        return this.http.get<any>(environment.apiUrl+'api/core/periodos')
    }
    savePeriodo(periodo:any){
        return this.http.post<any>(environment.apiUrl+"api/core/periodo",periodo)
    }
    getPeriodo(idPeriodo:number){
        return this.http.get<any>(environment.apiUrl+'api/core/periodo/'+idPeriodo)
    }
    deletePeriodo(idPeriodo:number){
        return this.http.delete(environment.apiUrl+'api/core/deletePeriodo/'+idPeriodo)
    } 

    getEmpresas(idUsuario:number){
        return this.http.get<any>(environment.apiUrl+'api/core/empresas/'+idUsuario)
    }
    
    updateFile(file:any, idModulo:string, modulo:string, carpeta:string){
        let formData = new FormData()
        formData.append("file", file)
        formData.append("idModulo", idModulo)
        formData.append("modulo",modulo)
        formData.append("carpeta",carpeta)
        return this.http.post<any>(environment.apiUrl+"api/core/upload",formData)
    }
    /** Parametros */
    getParametrosModulo(idModulo:number){
        let idEmpresa = this.loopBackAuthService.load().puntoEmision.idEmpresa
        return this.http.get<any>(environment.apiUrl+'api/core/parametro/'+idModulo+'/'+idEmpresa)
    }
    getGrupoLista(idGrupos:string){
        let idEmpresa = this.loopBackAuthService.load().puntoEmision.idEmpresa
        return this.http.get<any>(environment.apiUrl+'api/core/grupoLista/'+idGrupos+'/'+idEmpresa)
    }

    getListaSri(idPadres:string){
        let idPeriodo = this.loopBackAuthService.load().periodo.id
        return this.http.get<any>(environment.apiUrl+'api/core/sriTabla/'+idPeriodo+'/'+idPadres)
    }
    /** Empresa */
    getAvatar(idEmpresa:number){
        return this.http.get<any>(environment.apiUrl+'api/core/urlAvatar/'+idEmpresa)
    }
    getEmprea(idEmpresa:number){
        return this.http.get<any>(environment.apiUrl+'api/core/empresa/'+idEmpresa)
    }
    saveEmprea(empresa:any){
        console.log("Guardar -->", empresa)
        return this.http.post<any>(environment.apiUrl+"api/core/empresa",empresa)
    }
    /** Establecimeinto */
    getSucursalesPorEmpresa(idEmpresa:number){
        return this.http.get<any>(environment.apiUrl+'api/core/sucursales/'+idEmpresa)
    }

    saveEstablecimiento(establecimiento:any){
        return this.http.post<any>(environment.apiUrl+'api/core/establecimiento',establecimiento)   
    }

    deleteEstablecimiento(idEmpresa:number, idEstablecimiento:number){
        return this.http.delete<any>(environment.apiUrl+'api/core/establecimiento/delete/'+idEstablecimiento+'/'+idEmpresa)
    }

    savePuntoEmision(puntoEmisionContador:any){
        return this.http.post<any>(environment.apiUrl+'api/core/puntoEmision',puntoEmisionContador)
    }
    deletePuntoEmision(idPuntoEmision:number){
        let idUsuario = this.loopBackAuthService.load().usuario.id
        return this.http.delete<any>(environment.apiUrl+'api/core/puntoEmision/delete/'+idPuntoEmision+'/'+idUsuario)
    }
    


    /** Firma electronica */
    getVencimientoFirma(){
        let idEmpresa = this.loopBackAuthService.load().puntoEmision.idEmpresa
        return this.http.get<any>(environment.apiUrl+'api/core/vencimientoFirma/'+idEmpresa)
    }

    updateFileFirma(file:any, clave:string){
        let formData = new FormData()
        let idEmpresa = this.loopBackAuthService.load().puntoEmision.idEmpresa
        formData.append("file", file)
        formData.append("clave", clave)
        formData.append("idEmpresa",idEmpresa)
        return this.http.post<any>(environment.apiUrl+"api/core/uploadFirma",formData)
    }
}