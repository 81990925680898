import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'authentication',
    templateUrl: './authentication.component.html',
    styleUrls: ['./authentication.component.scss']
})

export class AuthenticationComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}