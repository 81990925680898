import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {Observable} from "rxjs";
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import {LoopBackAuthService} from './loop-back-auth.service'
@Injectable()
export class ApiUsuarioService{
    constructor( 
        private http: HttpClient,
        private loopBackAuthService:LoopBackAuthService){}
    getAdministrativoUsuarios(){
        return this.http.get<any>(environment.apiUrl+'api/usuario/administrativoUsuarios')
    }

    getAdministrativoUsuariosSucursalPuntoEmision(){
        let idPuntoEmision = this.loopBackAuthService.load().puntoEmision.idSucursalPuntoEmision
        return this.http.get<any>(environment.apiUrl+'api/usuario/administrativoUsuarios/'+idPuntoEmision)
    }

    setUsuario(usuario:any){
        return this.http.post<any>(environment.apiUrl+"api/usuario/save",usuario)
    }

    getUsuario(idUsuario:number){
        return this.http.get<any>(environment.apiUrl+'api/usuario/find/'+idUsuario)
    }

    deleteUsuario(idUsuario:number){
        return this.http.delete(environment.apiUrl+'api/usuario/delete/'+idUsuario+"/"+1)
    }
    /** Authentication */
    getLogin(login:any){
        let usuario ={
            username:login.username,
            password:login.password
        }
        console.log("datos -->", JSON.stringify(usuario))
        return this.http.post<any>(environment.apiUrl+"login",JSON.stringify(usuario))
    }
    setOlvideContrasenia(item:string){
        return this.http.post<any>(environment.apiUrl+"api/olvideclave",item)
    }
    setReseteoClave(idUsuario:string){
        return this.http.get<any>(environment.apiUrl+"api/reseteoClave/"+idUsuario)
    }
    getLoopBackAuth(idUsuario:number){
        console.log("idusaurio -->", idUsuario)
        return this.http.get<any>(environment.apiUrl+'api/loopBackAuth/'+idUsuario)
    }
    setNewPassword(password:string, idUsuario:number){
        let item ={id:idUsuario,contrasenia:password}
        return this.http.post<any>(environment.apiUrl+"api/usuario/newPassword",item)
    }

    getUrlAvatar(idUsuario:number){
        return this.http.get<any>(environment.apiUrl+'api/usuario/urlAvatar/'+idUsuario)
    }

    /** Roles */

    getEmpresaSucursal(){
        return this.http.get<any>(environment.apiUrl+'api/usuario/usuarioCrudEmpresaSucursal')
    }
    getEmpresaRol(){
        let idEmpresa = this.loopBackAuthService.load().puntoEmision.idEmpresa
        return this.http.get<any>(environment.apiUrl+'api/usuario/usuarioCrudEmpresaRol/'+idEmpresa)
    }
    /** Cambiar Menu */
    getMenu(){
        let idUsuario = this.loopBackAuthService.load().usuario.id
        let idPuntoEmision = this.loopBackAuthService.load().puntoEmision.idEmpresa
        return this.http.get<any>(environment.apiUrl+'api/menu/'+idPuntoEmision+'/'+idUsuario)
    }
    getPeriodos(){
        let idUsuario = this.loopBackAuthService.load().usuario.id
        let idEmpresa = this.loopBackAuthService.load().puntoEmision.idEmpresa
        return this.http.get<any>(environment.apiUrl+'api/core/periodos/'+idEmpresa+'/'+idUsuario)
    }

    validarEmpleadoEsPropietario(idEmpleado:number){
        return (this.loopBackAuthService.load().empleado.filter((x:any)=> x.id == idEmpleado).length > 0 ) ? true : false
    }
    validarEsAdministrador(){
        return (this.loopBackAuthService.load().roles.filter((x:any)=> x.idRol == 1).length >  0) ? true : false
    }
    validarEsRRHH(){
        return (this.loopBackAuthService.load().roles.filter((x:any)=> x.idRol == 3).length >  0) ? true : false
    }
    validarEsSupervisor(){
        return (this.loopBackAuthService.load().roles.filter((x:any)=> x.idRol == 4).length >  0) ? true : false
    }
    validarEsSuperAdministrador(){
        return (this.loopBackAuthService.load().roles.filter((x:any)=> x.idRol == -1).length >  0) ? true : false
    }
    validarAccesoBoton(tipo:string){
        let esAdmin = this.validarEsAdministrador()
        let esRRHH = this.validarEsRRHH()
        let supervisor = this.validarEsSupervisor()
        switch (tipo) {
            case 'edit':
                return (esAdmin || esRRHH ) ? true : false
            break;
            case 'nuevo':
                return (esAdmin || esRRHH || supervisor) ? true : false
            break;
            case 'eliminar':
                return (esAdmin || esRRHH) ? true : false
            break;
            case 'anular':
                return esAdmin
            break;
            case 'finalizar':
                return (esAdmin || esRRHH) ? true : false
            break;
            case 'ver':
                return (esAdmin || esRRHH) ? true : false
            break;
            case 'rechazar':
                return (esAdmin || esRRHH) ? true : false
            break;
            default:
                return false;
            break;
        }

    }
}
