import { NgModule } from '@angular/core';
import { IconsProviderModule } from '../../icons-provider.module';
import { NgZorroAntdModule, NZ_I18N, es_ES } from 'ng-zorro-antd';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgPipesModule} from 'ngx-pipes';
import {ApiInterceptorService} from './interceptor/api-interceptor.service'
import {LoaderService} from './service/loader.service'
import {LoopBackAuthService} from './service/loop-back-auth.service'
import {ExcelService} from './service/excel.service'
import {ApiCoreService} from './service/api-core.service'
/** Elemenmt */
import {ListaModuloComponent} from './element/lista-modulo/lista-modulo.component'
import {LoaderComponent} from './element/loader/loader.component'
import {AvatarComponent} from './element/avatar/avatar.component'
import es from '@angular/common/locales/es';
import { ApiUsuarioService } from './service/api-usuario.service';
import { ModalPorqueComponent } from './modal/modal-porque/modal-porque.component';
import { ListaDescripcionPipe } from './pipe/lista-descripcion.pipe';
import { RestrictInputDirective } from './directive/restrict-input.directive';

registerLocaleData(es);

@NgModule({
  declarations: [
    ListaModuloComponent,
    LoaderComponent,
    AvatarComponent,
    ModalPorqueComponent,
    ListaDescripcionPipe,
    RestrictInputDirective
  ],
  imports: [
    IconsProviderModule,
    NgZorroAntdModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CommonModule,
    NgPipesModule
  ],
  providers: [
    LoaderService,
    LoopBackAuthService,
    ApiCoreService,
    ApiUsuarioService,
    ExcelService,
    { provide: NZ_I18N, useValue: es_ES },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptorService, multi: true }
  ],
  exports:[
    IconsProviderModule,
    NgZorroAntdModule,
    FormsModule,
    ReactiveFormsModule,
    ListaModuloComponent,
    HttpClientModule,
    CommonModule,
    NgPipesModule,
    LoaderComponent,
    AvatarComponent,
    ModalPorqueComponent,
    ListaDescripcionPipe,
    RestrictInputDirective
  ]
})
export class SharedModule { }
