import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoopBackAuthService {
  constructor() { }
  public saveToken(token: string){
    localStorage.setItem("token",token)
  }

  public removeToken(){
    localStorage.removeItem('token')
  }

  public save(data: any){
    localStorage.setItem('LoopBackAuth', JSON.stringify(data))
  }

  public load(){
    return JSON.parse(localStorage.getItem('LoopBackAuth'))
  }
  public remove(){
    localStorage.removeItem('LoopBackAuth')
  }

  public saveTiempoExpira(tiempoExpiracion:string){
    localStorage.setItem('tiempoExpiracion',tiempoExpiracion)
  }

  public removeTiempoExpira(){
    localStorage.removeItem('tiempoExpiracion')
  }

  public getTiempoExpira(){
    return localStorage.getItem('tiempoExpiracion')
  }

  public getTiempo(){
    return localStorage.getItem('tiempo')
  }

  public removeTiempo(){
    localStorage.removeItem('tiempo')
  }

  public saveTiempo(){
    let milisegundos = new Date().getTime() + parseInt(this.getTiempoExpira())
    localStorage.setItem('tiempo',String(milisegundos))
  }

  public validarTiempoExpiracion(){
    if(new Date().getTime() <=  parseInt(localStorage.getItem('tiempo'))){
      return true
    }else{
      return false
    } 
  }
  public limpiarLocalStorage(){
    this.remove()
    this.removeTiempoExpira()
    this.removeToken()
    this.removeTiempo()
  }

  public validarAccesos(url:string){
    url=this.limpiarUrl(url);
    if(url.indexOf('authentication')!= -1) return true
    if(url.indexOf('errores')!= -1) return true
    if(url == '/administrador/perfil') return true
    let menu = this.load().menus.find((x:any)=>  x.path == url)
    if(menu != undefined){
      let acceso = this.load().accesos.find((x:any)=> x.idMenu == menu.id)
      if(acceso != undefined)
        return true
    }
    return false
  }
  private limpiarUrl(url:string){
    let partesUrl = url.split("/")
    for(let i=1; i < partesUrl.length;i++){
      let dato:any = partesUrl[i]
      if(!isNaN(dato)) partesUrl[i] = '?'
    }
    return partesUrl.join('/')
  }
}
