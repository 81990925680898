import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {SharedModule} from './core/shared/shared.module'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AdministradorComponent } from './layout/administrador/administrador.component';
import {PageWebComponent} from './layout/page-web/page-web.component';
import {AuthenticationComponent} from './layout/authentication/authentication.component'


@NgModule({
  declarations: [
    AppComponent,
    AdministradorComponent,
    PageWebComponent,
    AuthenticationComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule
  ],
  
  bootstrap: [AppComponent]
})
export class AppModule { }
