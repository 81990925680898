<nz-layout class="app-layout">
    <!-- Diseño del menu -->
    <nz-sider class="menu-sidebar"
              nzCollapsible
              [(nzCollapsed)]="isCollapsed"
              [nzBreakpoint]="'lg'"
              [nzCollapsedWidth]="0"
              [nzZeroTrigger]="zeroTrigger"
              >
      <div class="sidebar-logo">
        <img src="/assets/img/logo_interno.png" alt="logo">
        <!--a href="https://ng.ant.design/" target="_blank">
          <img src="/assets/img/logo_interno.png" class="img-responsive" alt="logo">
        </a -->
        
      </div>
        
      <!--div class="sidebar-empresa">
          <div *ngIf="isCollapsed" class="text-center">
            <img [src]="_logo" class="img-logo-empresa" >
          </div>
          <div *ngIf="!isCollapsed">
             <nz-select 
                nzPlaceHolder="Empresa" 
                [nzCustomTemplate]="empresaTemplate"
                [(ngModel)]="_empresa"
                (ngModelChange)="changeEmpresa()">
              <nz-option 
                *ngFor="let item of _empresas" 
                [nzLabel]="item.razonSocial" 
                [nzValue]="item.id">
              </nz-option>
            </nz-select>
            <ng-template 
              #empresaTemplate 
              let-selected> 
                <img [src]="_logo" class="img-logo-empresa">
                {{ selected.nzLabel }} 
              </ng-template>
          </div>
      </div -->
      <!--ul nz-menu  nzMode="inline" [nzInlineCollapsed]="isCollapsed" *ngFor="let item of _menu">
        <li nz-submenu 
          [nzTitle]="item.descripcion" 
          [nzIcon]="item.icono" 
          *ngIf="item.children.length > 0" >
          <ul>
            <li 
              nz-menu-item 
              *ngFor="let submenu of item.children"
              [nzSelected]="item.activo" 
              (nzOpenChange)="activarMenu(item)">
                <a [routerLink]="submenu.path">
                  {{submenu.descripcion}} {{submenu.children.length}}
                </a>
                <ul *ngIf="submenu.children.length > 0">
                  <li nz-menu-item *ngFor="let submenu2 of submenu.children">
                    <a [routerLink]="submenu2.path">
                      {{submenu2.descripcion}}
                    </a> 
                  </li>
                </ul>
                
            </li>
          </ul>
        </li>
        <li nz-menu-item *ngIf="item.children.length == 0" (nzOpenChange)="activarMenu(item)" [nzSelected]="item.activo">
          <a [routerLink]="item.path"><i nz-icon [nzType]="item.icono"></i> {{isCollapsed  ?'':item.descripcion}}</a>
        </li>
      </ul -->
      <ul nz-menu  nzMode="inline" [nzInlineCollapsed]="isCollapsed">
        <ng-container *ngTemplateOutlet="menuTpl; context: { $implicit: menus }"></ng-container>
        <ng-template #menuTpl let-menus>
          <ng-container *ngFor="let menu of menus">
            <li
              *ngIf="menu.children.length == 0"
              nz-menu-item
              [nzPaddingLeft]="menu.level * 24"
            >
              <a [routerLink]="menu.path">
                <i nz-icon [nzType]="menu.icono" *ngIf="menu.icono"></i>
                <span>{{ menu.descripcion }}</span>
              </a>
            </li>
            <li
              *ngIf="menu.children.length > 0"
              nz-submenu
              [nzPaddingLeft]="menu.level * 24"
              [nzTitle]="menu.descripcion"
              [nzIcon]="menu.icono"
            >
              <ul>
                <ng-container *ngTemplateOutlet="menuTpl; context: { $implicit: menu.children }"></ng-container>
              </ul>
            </li>
          </ng-container>
        </ng-template>
      </ul>
      <ul  nz-menu  nzMode="inline" [nzInlineCollapsed]="isCollapsed">
        <li nz-menu-item (click)="logout()">
          <i nz-icon [nzType]="'export'"></i> {{isCollapsed  ?'':'Salir'}}
        </li>
      </ul>
      <div class="text-center">
        <img [src]="_logo" style="width: 70%; margin-top: 60px;">
      </div>
      
    </nz-sider>
    <!-- Diseño del menu End -->

    <!-- Diseño del Contenido -->
    <nz-layout>
      <!-- Diseño del Barra Superios -->
      <nz-header>
        <div class="app-header">
          <div nz-row>
            <div nz-col [nzXs]="3" [nzLg]="1" [nzMd]="1">
              <span class="header-trigger" (click)="isCollapsed = !isCollapsed">
                <i class="trigger"
                   nz-icon
                   [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
                ></i>
            </span>
            </div>
            <div nz-col [nzXs]="0" [nzLg]="17" [nzMd]="12">
              <h1 class="text-center text-white">{{_sucursal.razonSocial}}</h1>
            </div>
            <div nz-col [nzXs]="10" [nzSm]="6" [nzMd]="11" [nzLg]="2" class="text-center">
              <button 
                nz-button 
                nz-dropdown 
                [nzDropdownMenu]="menu4" 
                nzTooltipTitle="Periodo" 
                nzTooltipPlacement="leftBottom"
                nz-tooltip>
                {{_periodo.descripcion}}
                <i nz-icon nzType="down"></i>
              </button>
              <nz-dropdown-menu #menu4="nzDropdownMenu">
                <ul nz-menu>
                  <li nz-menu-item *ngFor="let periodo of _periodos" (click)="setPeriodo(periodo)">
                    {{periodo.descripcion}}
                  </li>
                </ul>
              </nz-dropdown-menu>
            </div>
            <div nz-col [nzXs]="11" [nzSm]="6" [nzMd]="12" [nzLg]="4">
              <nz-avatar nzText="USER" [style.background-color]="'#4ca9ff'"></nz-avatar>
              <a nz-dropdown [nzDropdownMenu]="menu" class="btn-usuario">
                {{_userName | shorten: 30: ''}}
                <i nz-icon nzType="down"></i>
              </a>
              <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu nzSelectable>
                  <li nz-menu-item>
                    <a [routerLink]="['/usuario/perfil']">
                      <i nz-icon nzType="user"></i> Perfil
                    </a>
                  </li>
                  <li nz-menu-item>
                    <a [routerLink]="['/usuario/cambio-clave']">
                      <i nz-icon nzType="key"></i> Cambio clave
                    </a>
                  </li>
                  <li nz-menu-item (click)="logout()"><i nz-icon nzType="export"></i> Salir</li>
                </ul>
              </nz-dropdown-menu>
            </div>
          </div>
          
          
        </div>
      </nz-header>
      <!-- Diseño del Barra Superios End-->
      
      <!-- Diseño del Barra Contenido interno -->
      <nz-content>
        <div class="inner-content">
          <router-outlet></router-outlet>
        </div>
      </nz-content>
      <!-- Diseño del Barra Contenido interno End -->
    </nz-layout>
    <!-- Diseño del Contenido End-->
</nz-layout>

<ng-template #zeroTrigger>
  <i nz-icon nzType="menu-fold"></i>
</ng-template>
  