import { Component, OnInit } from '@angular/core';
import {LoopBackAuthService} from '../../core/shared/service/loop-back-auth.service'
import {ValidadorService} from '../../core/shared/service/validador.service'
import {ApiUsuarioService} from '../../core/shared/service/api-usuario.service'
import { environment } from '../../../environments/environment';
import { Router }   from '@angular/router';
import { isNull } from 'util';

@Component({
  selector: 'administrador',
  templateUrl: './administrador.component.html',
  styleUrls: ['./administrador.component.scss']
})
export class AdministradorComponent implements OnInit {
  public isCollapsed = false;
  public menus:any[] =[]
  public _periodos:any[]=[]
  public _periodo:any
  public _usuario:any
  public _empresas:any[]=[]
  public _empresa:number
  public _sucursal:any
  public _userName:string
  public _logo:string
  public _puntoEmision:any
  constructor(
    private loopBackAuthService:LoopBackAuthService,
    private apiUsuarioService:ApiUsuarioService,
    private val:ValidadorService,
    private router: Router,
  ) {
    //this._menu = this.val.getMenu(this.loopBackAuthService.load().menu)
    console.log("El menu  -->", this.menus)
    this.getMenu()
   }

  ngOnInit() {
    this._periodos = this.loopBackAuthService.load().periodos
    this._periodo = this.loopBackAuthService.load().periodo
    this._usuario = this.loopBackAuthService.load().usuario
    this._empresas = this.loopBackAuthService.load().empresas
    this._empresa = this.loopBackAuthService.load().puntoEmision.id
    this._sucursal = this.loopBackAuthService.load().puntoEmision
    this._userName = this._usuario.apellido.split(" ")[0]+" "+this._usuario.nombre.split(" ")[0]
    let ruta = this._sucursal.logo.slice(1,this._sucursal.logolength)
    this._logo =  environment.apiUrl+ruta
    console.log("Empresa -->", this._sucursal)
    
    this.isCollapsed = false
  }

  setPeriodo(periodo:any){
    let loopBackAuth = this.loopBackAuthService.load()
    loopBackAuth.periodo = periodo
    this.loopBackAuthService.save(loopBackAuth)
    this._periodo = periodo
    
  }

  setPeriodos(periodos:any[]){
    let loopBackAuth = this.loopBackAuthService.load()
    loopBackAuth.periodos = periodos
    this.loopBackAuthService.save(loopBackAuth)
  }
  setMenu(menu:any[]){
    let loopBackAuth = this.loopBackAuthService.load()
    loopBackAuth.menu = menu
    this.loopBackAuthService.save(loopBackAuth)
  }

  logout(){
    this.loopBackAuthService.removeToken()
    this.loopBackAuthService.remove()
    this.router.navigate(['/'])
  }

  changeEmpresa(){
    console.log("Cambio de empresa")
    let loopBackAuth = this.loopBackAuthService.load()
    loopBackAuth.puntoEmision = this._empresas.find((x:any) => x.id == this._empresa)
    this.loopBackAuthService.save(loopBackAuth)
    this.getPeriodos()
    this.getMenu()
    let ruta = this._sucursal.logo.slice(1,this._sucursal.logolength)
    this._logo = environment.apiUrl+ruta
    //window.location.reload();
  }

  getPeriodos(){
    this.apiUsuarioService.getPeriodos().subscribe(
      result=>{
        console.log("Periodos -->", result)
        if(!isNull(result)){
          this._periodos = result
          this._periodo = result[0]
          this.setPeriodos(this._periodos)
          this.setPeriodo(this._periodo)
        }else{
          this._periodos = []
          this._periodo = null
          this.setPeriodos(this._periodos)
          this.setPeriodo(this._periodo)
        }
       
      }
    )
  }

  getMenu(){
    this.apiUsuarioService.getMenu().subscribe(
      result=>{
        console.log("***Menu -->", result)
        if(!isNull(result)){
          this.setMenu(result)
          this.menus = this.val.getMenu(this.loopBackAuthService.load().menu)
        }else{
          this.setMenu([])
          this.menus = this.val.getMenu(this.loopBackAuthService.load().menu)
        }
        console.log("Menu -->", this.menus)
      }
    )
  }

  public activarMenu(item:any){
    this.val.desactivarMenu(this.menus)
    item.activo = true
  }
  
}
