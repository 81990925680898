import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router }   from '@angular/router';
import {LoaderService} from '../service/loader.service'
import {LoopBackAuthService} from '../service/loop-back-auth.service'

@Injectable({
  providedIn: 'root'
})
export class ApiInterceptorService implements HttpInterceptor {
  constructor( 
    private router:Router,
    private loopBackAuthService:LoopBackAuthService,
    private loaderService:LoaderService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loaderService.show()
    const token: string = localStorage.getItem('token');
   if (token) {
      request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
    }

    request = request.clone({ headers: request.headers.set('Accept', 'application/json') })

    return next.handle(request).pipe(
        map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              this.loaderService.hide()
              return event;
              
            }  
        }),
        catchError((error: HttpErrorResponse) => {
            if(error.status == 500){
              this.loopBackAuthService.removeToken()
              this.loopBackAuthService.remove()
              this.router.navigate(['/'])
            }
            this.loaderService.hide()
            return throwError(error);
          })
        );

  }
  
}